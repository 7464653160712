import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './PaymentPolicyDescription.st.css';

export enum PaymentPolicyDescriptionDataHook {
  root = 'PaymentPolicyDescription.root',
  subtitle = 'PaymentPolicyDescription.subtitle',
  policy = 'PaymentPolicyDescription.policy',
}

export const PaymentPolicyDescription = (): React.ReactNode => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  return (
    <div data-hook={PaymentPolicyDescriptionDataHook.root} className={classes.policies}>
      {checkout.paymentPolicies.map(
        ({subtitle, policy}, index) =>
          policy && (
            <div key={index}>
              <div data-hook={PaymentPolicyDescriptionDataHook.subtitle} className={classes.productName}>
                {subtitle}
              </div>
              <div data-hook={PaymentPolicyDescriptionDataHook.policy} className={classes.policy}>
                {policy}
              </div>
            </div>
          )
      )}
    </div>
  );
};
