import React, {ReactNode} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {MemberDetailsSummary} from './MemberDetailsSummary/MemberDetailsSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {MemberAddressNotValidError} from './MemberAddressNotValidError/MemberAddressNotValidError';
import {useMemberDetailsData} from './WithMemberDetailsData';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {SetAsDefaultCheckbox} from './SetAsDefaultCheckbox/SetAsDefaultCheckbox';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepId} from '../../../types/app.types';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  memberForm = 'MemberDetails.memberForm',
  addNewCheckbox = 'MemberDetails.addNewCheckbox',
  addNewCheckboxLabel = 'MemberDetails.addNewCheckboxLabel',
}

export interface MemberDetailsProps {
  Form: () => ReactNode;
  dropdownLabel: string;
  customField?: CustomFieldModel;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const MemberDetails = ({Form, dropdownLabel, customField}: MemberDetailsProps) => {
  const {
    areMemberDetailsValid,
    selectedAddressesServiceId,
    editMode,
    isAddNewChecked,
    setIsAddNewChecked,
    selectedAddressesService,
  } = useMemberDetailsData();
  const {
    memberStore: {addressesInfo},
    stepsManagerStore: {activeStep},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  const shouldShowAddressesDropdown = selectedAddressesServiceId && addressesInfo.addresses?.length > 0;
  const shouldShowSetAsDefaultCheckbox =
    selectedAddressesServiceId &&
    (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID || editMode) &&
    addressesInfo.addresses?.length > 1;

  const shouldHideAddNewCheckbox = activeStep.stepId === StepId.paymentAndPlaceOrder;
  const shouldCheckSelectedAddressesService = Boolean(selectedAddressesService);

  const shouldShowMemberDetailsSummary =
    selectedAddressesServiceId &&
    shouldCheckSelectedAddressesService &&
    selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID &&
    !editMode;
  const shouldShowForm =
    !selectedAddressesServiceId ||
    selectedAddressesServiceId === ADD_NEW_ADDRESS_ID ||
    editMode ||
    !selectedAddressesService;

  return (
    <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
      {shouldShowAddressesDropdown && <AddressDropdown dropdownLabel={dropdownLabel} />}
      {shouldShowMemberDetailsSummary && (
        <>
          <MemberDetailsSummary customField={customField} />
          {!areMemberDetailsValid && <MemberAddressNotValidError />}
        </>
      )}
      {shouldShowForm && <div data-hook={MemberDetailsDataHook.memberForm}>{Form()}</div>}
      {shouldShowSetAsDefaultCheckbox && <SetAsDefaultCheckbox />}
      {!selectedAddressesServiceId && !shouldHideAddNewCheckbox && (
        <Checkbox
          className={classes.formCheckbox}
          label={
            <Text data-hook={MemberDetailsDataHook.addNewCheckboxLabel}>
              {localeKeys.checkout.saveAddress.checkbox()}
            </Text>
          }
          checked={isAddNewChecked}
          data-hook={MemberDetailsDataHook.addNewCheckbox}
          onChange={({checked}) => setIsAddNewChecked(checked)}
        />
      )}
    </div>
  );
};
