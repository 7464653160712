import React from 'react';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './Footer.st.css';
import {
  PolicyButtonWithDialog,
  PolicyButtonLocation,
  PolicyType,
} from '../PolicyButtonWithDialog/PolicyButtonWithDialog';
import {PaymentPolicyDescription} from '../Checkboxes/PoliciesText/PaymentPolicyDescription';

export enum FooterDataHooks {
  TermsAndConditionsButton = 'FooterDataHooks.TermsAndConditionsButton',
  PrivacyPolicyButton = 'FooterDataHooks.PrivacyPolicyButton',
  PaymentPolicyButton = 'FooterDataHooks.PaymentPolicyButton',
  ReturnPolicyButton = 'FooterDataHooks.ReturnPolicyButton',
  DigitalItemPolicyButton = 'FooterDataHooks.DigitalItemPolicyButton',
  ContactUsButton = 'FooterDataHooks.ContactUsButton',
  CustomPolicyButton = 'FooterDataHooks.CustomPolicyButton',
}

export const Footer = ({dataHook}: {dataHook: string}) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {
      checkoutSettings: {termsAndConditions, privacyPolicy, returnPolicy, contactUs, customPolicy, digitalPolicy},
    },
    checkoutStore: {checkout},
  } = useControllerProps();

  if (
    !termsAndConditions.enabled &&
    !privacyPolicy.enabled &&
    !returnPolicy.enabled &&
    !contactUs.enabled &&
    !customPolicy.enabled &&
    !(digitalPolicy.enabled && checkout.hasDigitalItems) &&
    checkout.paymentPolicies.length === 0
  ) {
    return null;
  }

  return (
    <footer className={classes.footer} data-hook={dataHook}>
      <div className={classes.footerInner}>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.TermsAndConditionsButton}
          show={!!termsAndConditions.enabled}
          dialogTitle={localeKeys.checkout.terms_and_condition_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.TermsAndConditions}
          dialogDescription={() => termsAndConditions.value}>
          {localeKeys.checkout.footer.terms_and_condition_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.PrivacyPolicyButton}
          show={!!privacyPolicy.enabled}
          dialogTitle={localeKeys.checkout.privacy_policy_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.PrivacyPolicy}
          dialogDescription={() => privacyPolicy.value}>
          {localeKeys.checkout.footer.privacy_policy_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.ReturnPolicyButton}
          show={!!returnPolicy.enabled}
          dialogTitle={localeKeys.checkout.refund_cancellation_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.ReturnPolicy}
          dialogDescription={() => returnPolicy.value}>
          {localeKeys.checkout.footer.refund_cancellation_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.DigitalItemPolicyButton}
          show={Boolean(digitalPolicy.enabled) && checkout.hasDigitalItems}
          dialogTitle={localeKeys.checkout.digital_item_policy_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.DigitalItemPolicy}
          dialogDescription={() => digitalPolicy.value}>
          {localeKeys.checkout.footer.digital_item_policy_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.PaymentPolicyButton}
          show={checkout.paymentPolicies.length > 0}
          dialogTitle={localeKeys.checkout.payment_policy_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.PaymentPolicy}
          dialogDescription={PaymentPolicyDescription}>
          {localeKeys.checkout.footer.payment_policy_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.ContactUsButton}
          show={!!contactUs.enabled}
          dialogTitle={localeKeys.checkout.contact_us_modal.title()}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.ContactUs}
          dialogDescription={() => contactUs.value}>
          {localeKeys.checkout.footer.contact_us_button_text()}
        </PolicyButtonWithDialog>
        <PolicyButtonWithDialog
          className={classes.footerButton}
          dataHook={FooterDataHooks.CustomPolicyButton}
          show={!!customPolicy.enabled}
          dialogTitle={customPolicy.title}
          location={PolicyButtonLocation.FOOTER}
          policyType={PolicyType.CustomPolicy}
          dialogDescription={() => customPolicy.value}>
          {customPolicy.title}
        </PolicyButtonWithDialog>
      </div>
    </footer>
  );
};
