import React, {useEffect} from 'react';
import {classes} from '../BillingDetails/BillingDetails.st.css';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../../Form/FormLoader/FormLoader';
import {useBillingData} from '../../WithBillingData';
import {usePaymentsApi} from '../../../../../WithPaymentsApi/WithPaymentsApi';
import {updateMandatoryFields} from '../../../../../../../domain/utils/cashier.utils';
import {BillingForm} from '../../../../../Form/BillingForm/BillingForm';
import {getCountryFromAddressFormValues} from '../../../../../Form/form.utils';

export enum BillingDetailsFormDataHook {
  billingForm = 'BillingDetailsForm.billingForm',
}

export const BillingDetailsForm = () => {
  const {
    formsStore: {areFormsLoaded},
    paymentStore: {setCashierMandatoryFields},
  } = useControllerProps();

  const {billingFormData, updateContactCountry} = useBillingData();

  const {paymentsApi} = usePaymentsApi();

  const country = getCountryFromAddressFormValues(billingFormData.formValues);

  useEffect(
    () => {
      void updateContactCountry(country);
      void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  return (
    <>
      {!areFormsLoaded && /* istanbul ignore next */ <FormLoader />}
      {areFormsLoaded && (
        <div data-hook={BillingDetailsFormDataHook.billingForm} className={classes.forms}>
          <BillingForm {...billingFormData} />
        </div>
      )}
    </>
  );
};
