import {useControllerProps} from './Widget/ControllerContext';
import React, {useEffect} from 'react';
import {SlotsPlaceholder, useSlotPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotId} from '../../domain/utils/slotId';
import {usePaymentsApi} from './WithPaymentsApi/WithPaymentsApi';
import {PaymentMethod} from '@wix/cashier-payments-widget';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from './constants';

export const CheckoutSlot = /* istanbul ignore next: test slot */ ({
  slotId,
  className,
}: {
  slotId: SlotId;
  className: string;
}) => {
  const {
    slotsStore: {setSlotsParams, setStepId, setPaymentId},
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const {activePaymentId} = usePaymentsApi();
  const {experiments} = useExperiments();

  useEffect(
    () => {
      setSlotsParams(slotId);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  useEffect(() => {
    setStepId(slotId, activeStep.stepId);
  }, [activeStep]);

  useEffect(() => {
    setPaymentId(slotId, activePaymentId as PaymentMethod);
  }, [activePaymentId]);

  const shouldAddSlotSpacingAndDividers = experiments.enabled(SPECS.SlotSpacingAndDividers);
  const [Placeholder, isEmpty] = useSlotPlaceholder(slotId);

  return shouldAddSlotSpacingAndDividers ? (
    <div className={`${isEmpty ? '' : className}`}>
      <Placeholder />
    </div>
  ) : (
    <SlotsPlaceholder slotId={slotId} />
  );
};
